var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _c("AppNavigation"),
      _c(
        "v-main",
        { staticClass: "pa-0 background" },
        [
          _c("AppBar"),
          _c(
            "v-container",
            {
              staticClass: "py-4 mb-10",
              class: {
                "px-6": !_vm.$vuetify.breakpoint.mobile,
                "px-1": _vm.$vuetify.breakpoint.mobile,
              },
              attrs: { fluid: "" },
            },
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }