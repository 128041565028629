var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    { attrs: { color: "background", flat: "", height: "96px" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "mx-0 elevation-0 background",
          attrs: { fab: "", color: "white", small: "" },
          on: { click: _vm.toggleNavigation },
        },
        [_c("v-icon", [_vm._v(" mdi-menu ")])],
        1
      ),
      this.$vuetify.breakpoint.mobile ? _c("v-spacer") : _vm._e(),
      !this.$vuetify.breakpoint.mobile
        ? _c(
            "v-spacer",
            { staticClass: "d-flex justify-center align-center" },
            [
              _c(
                "v-btn-toggle",
                {
                  staticClass: "elevation-1",
                  attrs: {
                    borderless: "",
                    rounded: "",
                    color: _vm.activeItem.color,
                  },
                  model: {
                    value: _vm.active,
                    callback: function ($$v) {
                      _vm.active = $$v
                    },
                    expression: "active",
                  },
                },
                _vm._l(_vm.menu, function (item) {
                  return _c(
                    "v-btn",
                    {
                      key: item.value,
                      attrs: {
                        value: item.value,
                        to: item.path,
                        width: "150px",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "", color: item.color } }, [
                        _vm._v(" " + _vm._s(item.icon) + " "),
                      ]),
                      _c(
                        "span",
                        { class: `${item.color}--text ${item.colorModifier}` },
                        [_vm._v(_vm._s(item.title))]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        [
          _c(
            "v-menu",
            {
              attrs: {
                "offset-y": "",
                "nudge-bottom": "10",
                transition: "slide-y-transition",
                "max-width": "400px",
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            { attrs: { large: "", rounded: "" } },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          !_vm.$vuetify.breakpoint.mobile
                            ? _c(
                                "span",
                                {
                                  staticClass: "pl-1 grey--text text--darken-3",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.myInfo.username || _vm.myInfo.name
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-avatar",
                            { staticClass: "mx-2", attrs: { size: "32" } },
                            [
                              _c("img", {
                                attrs: { src: _vm.myInfo.avatarLink },
                              }),
                            ]
                          ),
                          _c("v-icon", { attrs: { color: "primary" } }, [
                            _vm._v(" mdi-chevron-down "),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "div",
                [
                  _c(
                    "v-list",
                    { attrs: { subheader: "", "two-line": "" } },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c("v-list-item-avatar", { attrs: { size: "50" } }, [
                            _c("img", {
                              attrs: { src: _vm.myInfo.avatarLink },
                            }),
                          ]),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.myInfo.name),
                                },
                              }),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.myInfo.phone ||
                                        `@${_vm.myInfo.username}`
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-subheader",
                        {
                          staticClass: "px-4",
                          staticStyle: { height: "24px" },
                        },
                        [_vm._v("Menu")]
                      ),
                      _c(
                        "v-list-item",
                        { on: { click: _vm.onClickAccount } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", [
                                _vm._v("mdi-account-circle-outline"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(" " + _vm._s(_vm.$t("account")) + " "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { on: { click: _vm.onClickLogout } },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-logout")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(" " + _vm._s(_vm.$t("logout")) + " "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }