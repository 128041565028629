var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      attrs: { app: "", overflow: "", color: "background" },
      scopedSlots: _vm._u([
        {
          key: "prepend",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "mx-2 mt-8 mb-8" },
                [
                  _c("v-img", {
                    attrs: {
                      src: "/assets/img/logo.png",
                      height: "40px",
                      contain: "",
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "append",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "pa-2" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "overline text-center",
                      staticStyle: {
                        "font-size": "8px !important",
                        "line-height": "initial",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.version) + " ")]
                  ),
                  _c("ChangeLanguageButton", { staticClass: "my-2" }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-4",
                      attrs: { block: "", small: "", depressed: "" },
                      on: {
                        click: function ($event) {
                          return _vm.onLogout()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-power"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("logout")) + " "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.drawer,
        callback: function ($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer",
      },
    },
    [
      _vm.currentApp === "shipping" || this.$vuetify.breakpoint.mobile
        ? _c(
            "v-list",
            { attrs: { nav: "", dense: "" } },
            [
              _vm._l(_vm.vslMenu, function (menu, mIndex) {
                return [
                  !Array.isArray(menu)
                    ? _c("v-subheader", { key: "subheader-" + mIndex }, [
                        _vm._v(_vm._s(menu.title)),
                      ])
                    : _c(
                        "v-list-item-group",
                        {
                          key: "menu-" + mIndex,
                          attrs: { color: "#3973d4" },
                          model: {
                            value: _vm.vslMenuIndex,
                            callback: function ($$v) {
                              _vm.vslMenuIndex = $$v
                            },
                            expression: "vslMenuIndex",
                          },
                        },
                        _vm._l(menu, function (item, i) {
                          return _c(
                            "v-list-item",
                            {
                              key: i,
                              staticClass: "rounded-pill",
                              attrs: {
                                to: { path: "/" + item.path },
                                disabled: !item.path,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.$vuetify.theme.themes.light.primary =
                                    "#3973d4"
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", {
                                    attrs: {
                                      color: "#3973d4",
                                      right: "",
                                      disabled: !item.path,
                                    },
                                    domProps: {
                                      textContent: _vm._s(item.icon),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", {
                                    domProps: {
                                      textContent: _vm._s(item.title),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.currentApp === "port" || _vm.$vuetify.breakpoint.mobile
        ? _c(
            "v-list",
            { attrs: { nav: "", dense: "" } },
            [
              _c("v-subheader", [
                _vm._v(_vm._s(_vm.$t("title-port-management"))),
              ]),
              _c(
                "v-list-item-group",
                {
                  attrs: { color: "#ce4900" },
                  model: {
                    value: _vm.vplMenuIndex,
                    callback: function ($$v) {
                      _vm.vplMenuIndex = $$v
                    },
                    expression: "vplMenuIndex",
                  },
                },
                _vm._l(_vm.vplMenu, function (item, i) {
                  return _c(
                    "v-list-item",
                    {
                      key: i,
                      staticClass: "rounded-pill",
                      attrs: { to: { path: "/" + item.path } },
                      on: {
                        click: function ($event) {
                          _vm.$vuetify.theme.themes.light.primary = "#ce4900"
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", {
                            attrs: { color: "#ce4900", right: "" },
                            domProps: { textContent: _vm._s(item.icon) },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", {
                            domProps: { textContent: _vm._s(item.title) },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }